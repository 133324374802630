.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  font-size: 14px;
  color: #333;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.cookie-banner a {
  color: var(--org-primary);
  text-decoration: none;
  font-weight: bold;
}

.cookie-banner a:hover {
  text-decoration: underline;
  color: var(--org-active);
}

.cookie-banner-btn.mybtn {
  padding: 5px 10px;
}

/* ***************************************
**************Responsiveness***********
*************************************** */

@media (max-width: 950px) {
  .cookie-banner-btn.mybtn {
    padding: 0.5rem 2rem;
  }
  .cookie-banner p {
    font-size: 1.4rem;
  }
}
