.mainloadercont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-yzaezf3dcmj div {
  box-sizing: border-box !important;
}
.ldio-yzaezf3dcmj > div {
  position: absolute;
  width: 134px;
  height: 134px;
  top: 33px;
  left: 33px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #969696 transparent #969696 transparent;
  animation: ldio-yzaezf3dcmj 1.5625s linear infinite;
}

.ldio-yzaezf3dcmj > div:nth-child(2),
.ldio-yzaezf3dcmj > div:nth-child(4) {
  width: 118px;
  height: 118px;
  top: 41px;
  left: 41px;
  animation: ldio-yzaezf3dcmj 1.5625s linear infinite reverse;
}
.ldio-yzaezf3dcmj > div:nth-child(2) {
  border-color: transparent var(--org-primary) transparent var(--org-primary);
}
.ldio-yzaezf3dcmj > div:nth-child(3) {
  border-color: transparent;
}
.ldio-yzaezf3dcmj > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-yzaezf3dcmj > div:nth-child(3) div:before,
.ldio-yzaezf3dcmj > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  top: -6px;
  left: 58px;
  background: #969696;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #969696;
}
.ldio-yzaezf3dcmj > div:nth-child(3) div:after {
  left: -6px;
  top: 58px;
  box-shadow: 128px 0 0 0 #969696;
}

.ldio-yzaezf3dcmj > div:nth-child(4) {
  border-color: transparent;
}
.ldio-yzaezf3dcmj > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-yzaezf3dcmj > div:nth-child(4) div:before,
.ldio-yzaezf3dcmj > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  top: -6px;
  left: 50px;
  background: var(--org-primary);
  border-radius: 50%;
  box-shadow: 0 112px 0 0 var(--org-primary);
}
.ldio-yzaezf3dcmj > div:nth-child(4) div:after {
  left: -6px;
  top: 50px;
  box-shadow: 112px 0 0 0 var(--org-primary);
}
.loadingio-spinner-double-ring-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  background-color: #f1f1f2;
}
.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
